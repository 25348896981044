.main {
  height: 100%;
  position: relative;
  background-color: #000;
}

.header,
.footer {
  padding: 1rem;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(#000000c7, #00000070, transparent);
}

.footer {
  background: linear-gradient(transparent, #00000070, #000000c7);
}

.hideable {
  transition: opacity 0.25s;
}

.user-inactive .hideable {
  opacity: 0;
  cursor: none;
  pointer-events: none;
}

.hideable--cursor {
  cursor: none;
}

.lock .lockable {
  visibility: hidden;
  pointer-events: none;
}

.title {
  max-width: 80%;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.volume-popover {
  min-height: 10rem !important;
}

::cue {
  background: transparent;
}

.captions-container {
  @apply space-y-2;
  padding: 0 1rem;
}

.caption {
  color: white;
  margin: auto;
  line-height: 185%;
  width: fit-content;
  border-radius: 2px;
  text-align: center;
  padding: 0.2em 1em;
  white-space: pre-wrap;
  background: #000000b5;
  box-decoration-break: clone;
}
