.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  pointer-events: none;
  justify-content: center;
  transition: opacity 0.25s ease-in-out;
}

.main:hover .overlay {
  opacity: 1;
  pointer-events: all;
}
